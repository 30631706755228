h1, h2, h3, h4, h5, h6 {
    color: currentColor;
}

/* menu */
.ant-menu-dark.ant-menu-inline {
    background-color: #2f4050 !important
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #2f4050 !important;
    -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: #2f4050 !important;
}

.checkbox-label {
    font-weight: 700 !important;
    margin-left: 9px !important;
}
.text-left{
    text-align: left;
}
.navbar-toggler {
    background-color: transparent !important;
    border-color: rgba(255,255,255,0) !important;
}

.carousel-control.left:hover, .carousel-control-prev:hover{
    background-image: none !important;
}

.carousel-control.right:hover, .carousel-control-next:hover{
    background-image: none !important;
}

.dropdown-menu .divider{
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    margin: -3px 0 0 -3px;
}
.app .lds-roller div:after{
    background: rgba(0, 0, 0, 0.44) !important;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.img-circle-lg {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
}
.summernote{
    padding: 16px;
}
.contact-items{
    padding: 0px 30px 15px 30px;
}

body {
    background-color: #f3f3f4;
}

#wrapper {
    background-color: #2f4050;
}